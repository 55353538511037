import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  showSignoutPopup = false;
  constructor(public dialog: MatDialog,) { }

  ngOnInit(): void {
  }
  
  toggleSignoutPopup() {
    this.showSignoutPopup = !this.showSignoutPopup;
  }
}
