<div class="grid-item1 d-lg-block d-none">
  <div class="text-center mb-2 mt-3">
    <img src="assets/images/gbstore-logo.svg" class="w-30" alt="logo">
  </div>
  <div class="text-center mb-4 mt-2">
    <label class="fw-bold store-name">GP STORE</label>
  </div>
  <hr>
  <div class="text-center mt-4 mb-2 position-relative">
    <img src="assets/images/order-logo.svg" class="w-30" alt="logo">
    <div class="edit">
      <img src="assets/images/edit.svg" class="w-30" alt="logo">
    </div>
  </div>
  <div class="text-center  mb-0">
    <label class="fw-bold foods ">GP FOODS</label>
  </div>
  <div class="text-center mb-4 mt-2 ">
    <img src="assets/images/location.svg" class="w-30 me-2" alt="location">
    <label class="location default">Nagercoil</label>
  </div>
  <!-- order-items -->

  <div class=" ps-3 ">
    <div class="my-4 row menu-items " (click)="onMenuItemClick('Orders')" [class.select] = "currentSideBar === 'Orders'" >
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',false)" class="w-25 me-2" alt="orders" >
      </div>
      <div class="col-3 text-center px-0 pointer hover-img" >
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',true)" class="w-25 me-2" alt="orders">
      </div>

      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Orders'}">Orders</div>
      </div>
    </div>
    <div class=" my-4 row menu-items " (click)="onMenuItemClick('FoodItems')"  [class.select] = "currentSideBar === 'FoodItems'" >
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',false)" class="w-25 me-2" alt="food-item">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img" >
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',true)" class="w-25 me-2" alt="food-item">
      </div>
      <!-- <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/food-item.svg" class="w-30 me-2" alt="food-item">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img" [ngClass]="{'hover-img': currentSideBar === 'FoodItems'}">
        <img src="assets/images/food-item-color.svg" class=" w-30 me-2 " alt="orders">
      </div> -->
      <div class="col-9 px-0 pointer">
        <div class="name title-color"[ngClass]="{'active-title': currentSideBar === 'FoodItems'}">Food Items</div>
      </div>
    </div>
    <div class=" my-4 row menu-items" (click)="onMenuItemClick('Financial')" [class.select] = "currentSideBar === 'Financial'" >

      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',false)" class="w-25 me-2" alt="financial">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',true)" class="w-25 me-2"  alt="financial">
      </div>
      <!-- <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/financial.svg" class="w-30 me-2" alt="financial">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img" [ngClass]="{'hover-img': currentSideBar === 'Financial'}">
        <img src="assets/images/financial-color.svg" class=" w-30 me-2 " alt="financial">
      </div> -->
      <div class="col-9 px-0 pointer">
        <div class="name title-color"  [ngClass]="{'active-title': currentSideBar === 'Financial'}">Financial</div>
      </div>
    </div>
    <div class=" my-4 row menu-items " (click)="onMenuItemClick('History')"  [class.select] = "currentSideBar === 'History'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',false)" class="w-25 me-2" alt="history">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',true)" class="w-25 me-2"  alt="history">
      </div>
      <!-- <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/history.svg" class="w-30 me-2" alt="history">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img">
        <img src="assets/images/history-color.svg" class=" w-30 me-2 " alt="orders">
      </div> -->
      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'History'}">History</div>
      </div>
    </div>
    <div class="my-5 row menu-items pointer"   [class.select] = "currentSideBar === 'LogOut'" data-bs-toggle="modal" data-bs-target="#staticBackdrop">

      <!-- <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc( 'log-out',currentSideBar === 'LogOut',false)" class="w-25 me-2" alt="log-out">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('log-out', currentSideBar === 'LogOut',true)" class="w-25 me-2"  alt="log-out">
      </div> -->
      <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/log-out.svg" class="w-30 me-2" alt="log-out">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img">
        <img src="assets/images/log-out-color.svg" class=" w-30 me-2 " alt="orders">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color" >Logout</div>
      </div>
    </div>

    <!-- Modal -->
<div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content pt-3 px-3">
      <div class="modal-body text-center ">
        <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
        <div class="popup-message mt-3">Do you want to logout your account</div>
      </div>
      <div class="modal-footer d-flex justify-content-evenly p-0">
          <button class="btn signout-btn " data-bs-dismiss="modal" aria-label="Close">No</button>
          <div class="vr px-0 "></div>
          <button class="btn signout-btn " (click)="toggleSignoutPopup()">Yes</button>
      </div>
    </div>
  </div>
</div>
  </div>
</div>


<!-- <div class="signout-overlay" >
  <div class="signout-content">
    <div class="sign">
      <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
      <div class="popup-message">Do you want to logout your account</div>
    </div>
    <hr class="mx-auto">
    <div class="popup-actions">
      <button class="btn signout-btn" (click)="toggleSignoutPopup()">No</button>
      <div class="vr px-0 "></div>
      <button class="btn signout-btn" routerLink="/login">Yes</button>
    </div>
  </div>
</div> -->
<!-- for Mobile -->
<div *ngIf="mobileData === 'orderpage-mobile'">
  <div class=" d-lg-none d-block">
  <div class="d-flex p-3 ps-0 pointer">
    <div class="text-start  mt-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
    aria-controls="offcanvasExample">
      <img src="assets/images/gbstore-logo.svg" class="w-30 logo" alt="logo">
    </div>
    <div class="text-start  mx-3 store mt-2 pointer">
      <label class="fw-bold store-name pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample" >GP STORE</label>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header px-4 pt-4 pb-0">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body pt-0">
        <div class="text-center mt-0 mb-2 position-relative">
          <img src="assets/images/order-logo.svg" class="w-30" alt="logo">
          <div class=" edit">
            <img src="assets/images/edit.svg" class="w-30" alt="logo">
          </div>
        </div>
        <div class="text-center  mb-0">
          <label class="fw-bold foods ">GP FOODS</label>
        </div>
        <div class="text-center mb-4 mt-2 ">
          <img src="assets/images/location.svg" class="w-30 me-2" alt="location">
          <label class="location default">Nagercoil</label>
        </div>
        <!-- order-items -->

  <div class=" ps-3 ">
    <div class="my-4 row menu-items pointer" (click)="onMenuItemClick('Orders')" [class.select] = "currentSideBar === 'Orders'" >
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',false)" class="w-25 me-2" alt="orders">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img" >
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',true)" class="w-25 me-2" alt="orders">
      </div>

      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Orders'}">Orders</div>
      </div>
    </div>
    <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('FoodItems')"  [class.select] = "currentSideBar === 'FoodItems'" >
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',false)" class="w-25 me-2" alt="food-item">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img" >
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',true)" class="w-25 me-2" alt="food-item">
      </div>
      <!-- <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/food-item.svg" class="w-30 me-2" alt="food-item">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img" [ngClass]="{'hover-img': currentSideBar === 'FoodItems'}">
        <img src="assets/images/food-item-color.svg" class=" w-30 me-2 " alt="orders">
      </div> -->
      <div class="col-9 px-0 pointer mt-1">
        <div class="name title-color"[ngClass]="{'active-title': currentSideBar === 'FoodItems'}">Food Items</div>
      </div>
    </div>
    <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('Financial')" [class.select] = "currentSideBar === 'Financial'" >

      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',false)" class="w-25 me-2" alt="financial">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',true)" class="w-25 me-2"  alt="financial">
      </div>
      <div class="col-9 px-0 pointer mt-1">
        <div class="name title-color"  [ngClass]="{'active-title': currentSideBar === 'Financial'}">Financial</div>
      </div>
    </div>
    <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('History')"  [class.select] = "currentSideBar === 'History'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',false)" class="w-25 me-2" alt="history">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',true)" class="w-25 me-2"  alt="history">
      </div>
      <div class="col-9 px-0 pointer mt-1">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'History'}">History</div>
      </div>
    </div>
    <div class="my-5 row menu-items pointer" >

      <div class="col-3 text-center px-0 pointer default-img">
        <!-- <img [src]="getImageSrc('log-out', currentSideBar === 'LogOut',false)" class="w-25 me-2" alt="log-out"> -->
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <!-- <img [src]="getImageSrc('log-out', currentSideBar === 'LogOut',true)" class="w-25 me-2"  alt="log-out"> -->
      </div>
      <div class="col-9 px-0 pointer mt-1">
        <div class="name title-color">Logout</div>
      </div>
    </div>
  </div>

      </div>
    </div>
  </div>
</div>
</div>