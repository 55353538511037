import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutComponent } from '../logout/logout.component';
declare var bootstrap: any; 
@Component({
  selector: 'app-sidenav-items',
  templateUrl: './sidenav-items.component.html',
  styleUrls: ['./sidenav-items.component.css']
})
export class SidenavItemsComponent implements OnInit {
  @Input() mobileData:any;
  @Output() menuItemClicked = new EventEmitter<string>();
  activeItem: string | null = null;
  currentSideBar: 'Orders' | 'FoodItems' | 'Financial' | 'History'| 'LogOut' ='Orders';
  hoverState: boolean = false;
  showSignoutPopup = false;
  constructor(private renderer: Renderer2,private router: Router,) { }

  ngOnInit(): void {
  }
  onToggleOffcanvas() {
    const offcanvasElement = document.getElementById('offcanvasExample');
    if (offcanvasElement) {
      const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
      offcanvas.toggle();
    }
  }
  onMenuItemClick(menuItem: 'Orders' | 'FoodItems' | 'Financial' | 'History'): void {
    this.menuItemClicked.emit(menuItem);
    this.currentSideBar = menuItem;
    const offcanvasElement = document.querySelector('.offcanvas.offcanvas-start.show');
    if (offcanvasElement) {
      this.renderer.removeClass(offcanvasElement, 'show');
    }
    const backdropElement = document.querySelector('.offcanvas-backdrop.fade.show');
    if (backdropElement) {
      this.renderer.removeClass(backdropElement, 'offcanvas-backdrop');
      this.renderer.removeClass(backdropElement, 'fade');
      this.renderer.removeClass(backdropElement, 'show');
    }
  }
  closeOffcanvas(): void {
    const offcanvasElement = document.querySelector('.offcanvas.offcanvas-start.show');
    if (offcanvasElement) {
      this.renderer.removeClass(offcanvasElement, 'offcanvas');
      this.renderer.removeClass(offcanvasElement, 'offcanvas-start');
      this.renderer.removeClass(offcanvasElement, 'show');
    }
  }
  getImageSrc(item: 'Orders' | 'food-item' | 'financial' | 'history' , isActive: boolean,  isHover: boolean): string {
    // Return the appropriate image src based on the active state
 
    const baseSrc = 'assets/images/';
    const suffix = isActive || isHover  ? '-color.svg' : '.svg';
    return `${baseSrc}${item.toLowerCase().replace(/ /g, '-')}${suffix}`;
  }
  toggleSignoutPopup() {
    const backdropElement = document.querySelector('.modal-backdrop.fade.show');
    if (backdropElement) {
      this.renderer.removeClass(backdropElement, 'modal-backdrop');
      this.renderer.removeClass(backdropElement, 'fade');
      this.renderer.removeClass(backdropElement, 'show');
    }
    this.router.navigate(['/login']);
  }
}
