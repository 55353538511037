
<div class=" overflow-auto row"  >
  <div class=" col-8">
  <table class="financial-table mt-2">
    <thead class="py-3 financial-head">
      <tr class="financial-table-head">
        <th class="text-center financial-order-column py-3 px-1 ">Order ID</th>
        <th class="text-center financial-order-column py-3 px-1 ">Product Details</th>
        <th class="text-center financial-order-column py-3 px-1 ">Amount/Commission</th>
        <th class="text-center financial-order-column py-3 px-1 ">Action</th>
        <th class="text-center financial-order-column py-3 px-1 "> Bill Status</th>
    </tr>
 </thead>
    <tbody class="financial-body">
      <tr class="py-2 order-row financial-head-solid m-0 mb-3" *ngFor="let order of orderListData">
        <td class="text-center mt-2 product-details default financial-order-column py-0 px-1 product-name mt-3">{{order.Orderid}}</td>
        <td class="financial-order-column py-0 px-1 product-name">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center">
            <li>{{order.productDetails}}</li>
          </ul>
            <div  class="additional-order title-color text-center">+ 2 more items</div>
        </td>
        <td class="financial-order-column text-center financial-product-details default py-0 px-1 product-name mt-3">${{order.amount}}</td>
        <td class="text-center px-1 py-0  financial-order-column  product-name mt-2">
          <div class="d-flex justify-content-evenly">
            <button class="btn completed px-md-3 px-2 py-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Completed</button>
          </div>
        </td>
        <!-- <td class="text-center px-1 py-0  history-order-column">
          <div class="d-flex justify-content-evenly">
            <button class="btn cancel px-md-3 px-2 py-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#cancelId" aria-controls="offcanvasRight">Cancel</button>
          </div>
        </td> -->
        <td class="financial-order-column text-center paid">paid</td>
      </tr>
    </tbody>
  </table>
</div>
<div  class="col-4 text-center position-relative " >
  <div class="card">
    <div class="card-bady p-3">
  <div class="calendar text-end">
    <img src="/assets/images/calendar.svg" alt="action" class="action-icon  me-2 me-lg-0">
  </div>
  </div>
</div>
  <div class="card">
    <div class="card-bady p-3">
      <h1 class="me-1 pointer order-complete order-font fw-bold mt-2 text-center" >05</h1>
      <h3 class="order-complete orders-date text-center">No of orders on 26/08/2024</h3>
      <hr class="mx-auto">
      <div class="d-flex justify-content-between">
        <div class=" px-0">
       <div class="text-center financial-product-details default">$146</div>
       <div class="title-color text-center mt-2 details-font">Revenue on 
        26/08/2024</div>
      </div>
       <div class="vr px-0 "></div>
       <div class=" px-0">
       <div class="text-center financial-product-details default">$46</div>
       <div class="title-color text-center mt-2 details-font">commission on 
        26/08/2024</div>
      </div>
      </div>
      <hr class="mx-auto">
      <div class="d-flex justify-content-between my-3">
      <div class="text-start payment-paid details-font">No of orders payment paid</div>
      <div class="text-end payment-paid details-font">13</div>
    </div>
      <div class="d-flex justify-content-between my-3">
      <div class="text-start payment-pending details-font">No of orders payment pending</div>
      <div class="text-end payment-pending details-font">02</div>
    </div>
    <hr class="mx-auto">
    <div class="details-font d-flex justify-content-between my-3">
      <div>Total no of orders complete</div>
      <div>13</div>
    </div>
    <div class="details-font d-flex justify-content-between my-3">
      <div>Total no of orders cancele</div>
      <div>01</div>
    </div>
    <div class="details-font d-flex justify-content-between my-3">
      <div>Total no of orders reject</div>
      <div>01</div>
    </div>
    </div>
  </div>
</div>
<!-- orer-delivery -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <div class="d-flex">
    <div class="default title-color mt-1 me-4" id="offcanvasRightLabel">Order Id</div>
    <div class="text-center  product-details  name"> 1000000012</div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
    <!--Complete Order -->
    <div class="offcanvas-body py-4  overflow-auto" >
      <div class=" d-flex my-2">
      <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
<img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
   <div>
    <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
    <div  class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
  </div>
   </div>
   <hr class="hr-color">
   <div class=" d-flex">
    <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
 <div>
  <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
  <div  class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
</div>
 </div>
 <div class="border my-4"></div>

 <div class="d-flex">
  <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery" >
  <div>
  <div class=" mb-0 product-details additional-info">Delivery by</div>
  <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
</div>
</div>

<div class="d-flex my-4">
  <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time" >
  <div class="">
  <div class=" mb-0 product-details additional-info">Time Slot</div>
  <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
</div>
</div>

<div class="border my-4"></div>
<div class="my-4">
<div class="d-flex justify-content-between px-4">
<ul class="p-0 mb-3 default">
  <li>Chicken Biriyani</li>
</ul>
<div>* 1</div>
<div><span>$ </span>150</div>
</div>
<div class="d-flex justify-content-between px-4">
  <ul class="p-0 mb-3  default">
    <li>Mutton Biriyani</li>
  </ul>
  <div>* 2</div>
  <div><span>$ </span>400</div>
  </div>
</div>
  <div class="border-style"></div>
<div class="d-flex justify-content-between px-4 my-4">
  <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
  <div class="default product-details"><span>$ </span>550</div>
</div>
  <div class="border-style"></div>
  <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
<!-- body end -->
    </div>
     <!--Cancelled Order -->
     <!-- <div class="offcanvas-body py-4  overflow-auto" >
      <div class=" d-flex my-2">
<img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
   <div>
    <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
    <div  class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
  </div>
   </div>
   <hr class="hr-color">
   <div class=" d-flex">
    <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
 <div>
  <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
  <div  class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
</div>
 </div>
 <div class="border my-4"></div>

 <div class="d-flex">
  <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery" >
  <div class="value"> </div>
</div>

<div class="d-flex my-4">
  <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time" >
  <div class="">
  <div class=" mb-0 product-details additional-info">Time Slot</div>
  <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
</div>
</div>

<div class="border my-4"></div>
<div class="my-4">
<div class="d-flex justify-content-between px-4">
<ul class="p-0 mb-3 default">
  <li>Chicken Biriyani</li>
</ul>
<div>* 1</div>
<div><span>$ </span>150</div>
</div>
<div class="d-flex justify-content-between px-4">
  <ul class="p-0 mb-3  default">
    <li>Mutton Biriyani</li>
  </ul>
  <div>* 2</div>
  <div><span>$ </span>400</div>
  </div>
</div>
  <div class="border-style"></div>
<div class="d-flex justify-content-between px-4 my-4">
  <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
  <div class="default product-details"><span>$ </span>550</div>
</div>
  <div class="border-style"></div>
  <div class="text-center mb-4 my-4 cancel-comment">Order cancelled</div>

    </div> -->
  </div>
</div>

