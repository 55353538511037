import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css']
})
export class FinancialComponent implements OnInit {
  selectedMenuItem:string =''
  orderListData = [
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }
  onMenuItemSelected(menuItem: string): void {
    this.selectedMenuItem = menuItem;
  }
  ngAfterViewInit(): void {
    $('#datepicker').datepicker({}) 
  }
  onClose() {
    // this.Display = 'new-order';
  }
}
