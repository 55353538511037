import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
 import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  forgotPasswordForm!: FormGroup;
  otpVerificationForm!: FormGroup;
  changePasswordForm!: FormGroup;
  isSubmitting: boolean = false;
  passwordVisible: boolean = false;
  currentSection: string = 'login';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  userId: string = "";

  constructor( private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,  private authService: AuthService) {
      this.authService.userInfo.subscribe(info => {
        this.userId = info.id;
        localStorage.setItem('userId',this.userId);
      })
     }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      mobileInput: [''],
      emailInput: [''],
      password: ['', Validators.required],
      rememberme: [false]
    });



    this.forgotPasswordForm = this.fb.group({
      mobileInput: ['', Validators.required],
      emailInput: ['', [Validators.required, Validators.email]],
    });

    this.otpVerificationForm = this.fb.group({
      otp: ['', Validators.required]
    });

    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    });
    this.subscribeToInputChanges(this.loginForm);
    this.subscribeToInputChanges(this.forgotPasswordForm);
  }

  get f() {
    return this.loginForm.controls;
  }

  subscribeToInputChanges(form: FormGroup): void {
    const mobileInputControl = form.get('mobileInput');
    const emailInputControl = form.get('emailInput');
  
    if (mobileInputControl && emailInputControl) {
      mobileInputControl.valueChanges.subscribe(value => {
        if (value) {
          // Disable email input if mobile input has value
          emailInputControl.disable({ emitEvent: false });
        } else {
          // Enable email input if mobile input is cleared
          emailInputControl.enable({ emitEvent: false });
        }
      });
  
      emailInputControl.valueChanges.subscribe(value => {
        if (value) {
          // Disable mobile input if email input has value
          mobileInputControl.disable({ emitEvent: false });
        } else {
          // Enable mobile input if email input is cleared
          mobileInputControl.enable({ emitEvent: false });
        }
      });
    }
  }
  onLogin(): void {
    this.isSubmitting = true;
  
    // Validate the form before proceeding
    if (this.loginForm.invalid) {
      this.isSubmitting = false;
      return;
    }
  
    // Retrieve the form controls
    const mobileInputControl = this.loginForm.get('mobileInput');
    const emailInputControl = this.loginForm.get('emailInput');
    const passwordControl = this.loginForm.get('password');
    const rememberMeControl = this.loginForm.get('rememberme');
  
    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value;
    const emailInput = emailInputControl?.value?.trim();
    const password = passwordControl?.value?.trim();
    const rememberMe = rememberMeControl?.value;
  
    // Initialize the variable
    let emailOrPhone: string | undefined;
  
    // Prepare the request body based on the available input
    if (mobileInput?.e164Number) {
      emailOrPhone = mobileInput.e164Number;
    } else if (emailInput) {
      emailOrPhone = emailInput;
    }
  
    // Ensure emailOrPhone and password are defined
    if (emailOrPhone && password) {
      const requestPayload = {
        emailOrphone: emailOrPhone,
        password: password,
        rememberme: rememberMe,
        userType:"vendor"
      };
    
      this.authService.login(requestPayload).subscribe({
        next: (res) => {
          this.authService.UserAction.subscribe(info=>{
            if(info ==="changepassword"){
             this.currentSection = 'changePassword';
            }
          else{
          this.isSubmitting = false;
          this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          this.router.navigate(['/orders-page']);
          }
        }) 
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.error.errors?.email?.[0] === "Email not verified, Please Verify Email") {
            // Handle the case where the email is not verified
          }else if (err.error.errors?.LoginError?.[0] === "User doesn't exist.") {
            // Handle the case where the user doesn't exist
            alert('User does not exist.Please contact the Administrator for assistance.');
          } 
          else if (err.error.errors?.LoginError?.[0] === "Your account has been disabled. Please contact the Administrator for assistance.") {
            // Handle the case where the user doesn't exist
            alert('Your account has been disabled. Please contact the Administrator for assistance.');
          }else {
            this.snackBar.open('Username or Password is Incorrect', 'close', { duration: 3000, verticalPosition: 'top' });
          }
        }
      });
    } else {
      this.isSubmitting = false;
      this.snackBar.open('Please fill in all required fields', 'close', { duration: 3000, verticalPosition: 'top' });
    }
  }
  onForgotPassword(): void {

    const mobileInputControl = this.forgotPasswordForm.get('mobileInput');
    const emailInputControl = this.forgotPasswordForm.get('emailInput');
    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value || '';
    const emailInput = emailInputControl?.value || '';

    // Initialize the variable
    let emailOrphone: string | undefined;
    let userType = "vendor";
    // Prepare request body
    if (mobileInput && mobileInput.e164Number) {
      emailOrphone = mobileInput.e164Number.replace(/^\+/, '');
    }
    if (emailInput) {
      emailOrphone = emailInput;
    }
    if (emailOrphone) {

      this.http.post(`${environment.apiUrl}/users/forgotpassword-send-code`, { emailOrphone,userType  }).subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            this.snackBar.open('OTP sent successfully', 'close', { duration: 3000, verticalPosition: 'top' });
            localStorage.setItem('userId', data.users.id);
            this.showOtpVerification();
          } else {
            this.snackBar.open('Failed to send OTP', 'close', { duration: 3000, verticalPosition: 'top' });
          }
        },
        error: () => {
          this.snackBar.open('Error occurred while sending OTP', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      });
    }
  }

  onOtpVerification(): void {
    if (this.otpVerificationForm.invalid) {
      return;
    }
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: userId,
      id: userId,
      code: this.otpVerificationForm.get('otp')?.value
    };
    this.http.post(`${environment.apiUrl}/users/forgotpassword-code-validator`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.snackBar.open('OTP verified successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          this.showChangePassword();
        } else {
          this.snackBar.open('Invalid OTP', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: (err) => {
        console.error('Error during OTP verification:', err); // Debugging log
        this.snackBar.open('Error occurred while verifying OTP', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }

  onChangePassword(): void {

    if (this.changePasswordForm.get('newPassword')?.value !== this.changePasswordForm.get('confirmPassword')?.value) {
      this.snackBar.open('Passwords do not match', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const userId = localStorage.getItem('userId');
    if (!userId ) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      newPassword: this.changePasswordForm.get('newPassword')?.value
    };

    this.http.post(`${environment.apiUrl}/users/change-password-update`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          console.log(data,"data-change");
          
          this.snackBar.open('Password changed successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          localStorage.removeItem('userId'); // Clear userId from local storage
          this.currentSection = 'login';
        } else {
          this.snackBar.open('Failed to change password', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: () => {
        this.snackBar.open('Error occurred while changing password', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

    showForgotPassword(): void {
    this.currentSection = 'forgetPassword';
  }

  showOtpVerification(): void {
    this.currentSection = 'otpVerification';
  }

  showChangePassword(): void {
    this.currentSection = 'changePassword';
  }

}
