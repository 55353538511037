<div class="grid-container">
  <div class="grid-item1 d-lg-block d-none">
    <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)"></app-sidenav-items>
  </div>
  <!-- for Mobile -->
  <div class=" d-lg-none d-block"></div>
  <!-- <hr class="my-2"> -->
  <div class="px-md-4 mt-0 container-lg ">
    <!-- order-details -->
    <div class=" d-lg-none d-block">
      <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)" [mobileData]="mobileData"></app-sidenav-items>
    </div>
    <div class="row">
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title">New Orders</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer live-order order-font fw-bold mt-2 ">02</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title">Order in Progress</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer order-complete order-font fw-bold mt-2 ">05</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading  p-2 h-100">
          <h3 class="title">Orders Completed Today</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer order-today order-font fw-bold mt-2 ">10</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title">Total No of Orders </h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer total-order order-font fw-bold mt-2 ">100</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedMenuItem === 'Orders'">
      <div class="d-lg-block d-none mt-4">
        <div class="row">
          <div class="text-start col-6">
            <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
              [class.selected]="currentMenuBar === 'liveOrders'"
              [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
            <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
              [class.selected]="currentMenuBar === 'completed'"
              [ngClass]="{'selected-title': currentMenuBar === 'completed'}">Completed</div>
            <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('cancel')"
              [class.selected]="currentMenuBar === 'cancel'"
              [ngClass]="{'selected-title': currentMenuBar === 'cancel'}">Cancel</div>
          </div>
          <div class="d-flex justify-content-end col-6">
            <span class="date default mt-2">{{ date }} - {{ time }}</span>
          </div>
        </div>
      </div>

      <div class="  d-lg-none d-block mt-4">
        <div class="text-start d-flex justify-content-between">
          <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
            [class.selected]="currentMenuBar === 'liveOrders'"
            [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
          <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
            [class.selected]="currentMenuBar === 'completed'"
            [ngClass]="{'selected-title': currentMenuBar === 'completed'}">Completed</div>
          <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('cancel')"
            [class.selected]="currentMenuBar === 'cancel'" [ngClass]="{'selected-title': currentMenuBar === 'cancel'}">
            Cancel</div>
        </div>


      </div>
      <!-- order-details table -->
      <div class="border"></div>
      <div class="  d-lg-none d-block my-md-0 my-3">
        <div class="d-flex justify-content-end ">
          <span class="date default mt-2">{{ date }} - {{ time }}</span>
        </div>
      </div>
      <div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'liveOrders'">
        <table class="title-table mt-2">
          <thead class="table-head py-3">
            <tr class="title-table-head ">
              <th class="text-center order-column p-3 px-1">Order ID</th>
              <th class="text-center product-column p-3  px-1">Product Details</th>
              <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
              <th class="text-center delivery-column p-3 px-1">Delivery by</th>
              <th class="text-center time-column p-3 px-1">Time Slot</th>
              <th class="text-center amount-column p-3 px-1">Amount</th>
              <th class="text-center action-column p-3 px-1">Action</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of orderListData">
              <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
                {{order.Orderid}}</td>
              <td class="product-column py-0 px-1">
                <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
                  <li>{{order.productDetails}}</li>
                </ul>
                <div class="additional-order title-color text-center">+ 2 more items</div>
              </td>
              <td class="orderBy-coloum py-0 px-1">
                <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
                <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
              </td>
              <td class="px-md-1 delivery-column py-0 px-1">
                <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
                <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div>
              </td>
              <td class="px-md-1 time-column py-0 px-1">
                <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
                <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
              </td>
              <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
                ${{order.amount}}</td>
              <td class="text-center px-2 py-0 mt-1 action-column">
                <div class="d-flex justify-content-evenly">
                  <button class="btn new-order px-3 py-2" type="button" data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" [ngClass]="{'new-order': orderStatus === 'new-order', 'progress-order': orderStatus === 'in-progress'}" (click)="selectOrder(order)">
                    {{ orderStatus === 'new-order' ? 'New Order' : 'In Progress' }}<img
                      src="/assets/images/arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1" ></button>
                  <!-- <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1"> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'completed'">
        <table class="title-table mt-2">
          <thead class="table-head py-3">
            <tr class="title-table-head ">
              <th class="text-center order-column p-3 px-1">Order ID</th>
              <th class="text-center product-column p-3  px-1">Product Details</th>
              <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
              <th class="text-center delivery-column p-3 px-1">Delivery by</th>
              <th class="text-center time-column p-3 px-1">Time Slot</th>
              <th class="text-center amount-column p-3 px-1">Amount</th>
              <th class="text-center action-column p-3 px-1">Action</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of orderListData">
              <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
                {{order.Orderid}}</td>
              <td class="product-column py-0 px-1">
                <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
                  <li>{{order.productDetails}}</li>
                </ul>
                <div class="additional-order title-color text-center">+ 2 more items</div>
              </td>
              <td class="orderBy-coloum py-0 px-1">
                <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
                <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
              </td>
              <td class="px-md-1 delivery-column py-0 px-1">
                <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
                <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div>
              </td>
              <td class="px-md-1 time-column py-0 px-1">
                <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
                <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
              </td>
              <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
                ${{order.amount}}</td>
              <td class="text-center px-2 py-0 mt-1">
                <div class="d-flex justify-content-evenly">
                  <button class="btn completed px-3 py-2" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Completed</button>
                  <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'cancel'">
        <table class="title-table mt-2">
          <thead class="table-head py-3">
            <tr class="title-table-head ">
              <th class="text-center order-column p-3 px-1">Order ID</th>
              <th class="text-center product-column p-3  px-1">Product Details</th>
              <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
              <th class="text-center delivery-column p-3 px-1">Delivery by</th>
              <th class="text-center time-column p-3 px-1">Time Slot</th>
              <th class="text-center amount-column p-3 px-1">Amount</th>
              <th class="text-center action-column p-3 px-1">Action</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of orderListData">
              <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
                {{order.Orderid}}</td>
              <td class="product-column py-0 px-1">
                <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
                  <li>{{order.productDetails}}</li>
                </ul>
                <div class="additional-order title-color text-center">+ 2 more items</div>
              </td>
              <td class="orderBy-coloum py-0 px-1">
                <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
                <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
              </td>
              <td class="px-md-1 delivery-column py-0 px-1">
                <div class="text-center mb-0 product-details default product-name value"></div>
                <!-- <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div> -->
              </td>
              <td class="px-md-1 time-column py-0 px-1">
                <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
                <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
              </td>
              <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
                ${{order.amount}}</td>
              <td class="text-center px-2 py-0 mt-1">
                <div class="d-flex justify-content-evenly">
                  <button class="btn cancel px-md-3 px-2 py-2" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#cancelId" aria-controls="offcanvasRight">Cancel</button>
                  <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- orer-delivery -->

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <div class="d-flex">
            <div class="default title-color mt-1 me-4" id="offcanvasRightLabel">Order Id</div>
            <div class="text-center  product-details  name"> {{selectedOrder?.Orderid}}</div>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="onClose()"></button>
        </div>
        <div class="border-style"></div>
        <!-- New Order -->
        <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'new-order'">
          <div class=" d-flex my-2">
            <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
            <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
              <div class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
            </div>
          </div>
          <hr class="hr-color">
          <div class=" d-flex">
            <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.orderBy}} </h6>
              <div class="title-color default mt-1">{{selectedOrder?.additionalinfo}}</div>
            </div>
          </div>
          <div class="border my-4"></div>

          <div class="d-flex">
            <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
            <img src="/assets/images/loading.svg" class="me-3 mt-1" alt="loading">
            <div class="order-wait mt-1">Waiting for Delivery Partner</div>
          </div>

          <div class="d-flex my-4">
            <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
            <div class="">
              <div class=" mb-0 product-details additional-info">{{convertTimestampToDate(selectedOrder?.timeSlot)}}</div>
              <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
            </div>
          </div>

          <div class="border my-4"></div>
          <div class="my-4">
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3 default">
                <li>{{selectedOrder?.additionitem}}</li>
              </ul>
              <div>* {{selectedOrder?.additionitemTotal}}</div>
              <div><span>$ </span>150</div>
            </div>
            <!-- <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3  default">
                <li>Mutton Biriyani</li>
              </ul>
              <div>* 2</div>
              <div><span>$ </span>400</div>
            </div> -->
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between px-4 my-4">
            <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
            <div class="default product-details"><span>$ </span>550</div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between mb-4 my-4">
            <button type="submit" class="btn d-block m-auto reject-order ms-2 default title-color"
              (click)="rejectOrder()">Reject Order</button>
            <button type="submit" class="btn d-block m-auto accept-order me-2 default" (click)="acceptOrder()" aria-label="Close">Accept
              Order</button>
          </div>
          <!-- body end -->
        </div>
        <!-- Reject Order -->
        <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'reject-order'">
          <div class=" d-flex my-2">
            <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
            <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
              <div class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
            </div>
          </div>
          <hr class="hr-color">
          <div class=" d-flex">
            <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
              <div class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
            </div>
          </div>
          <div class="border my-4"></div>

          <div class="d-flex">
            <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
            <div class="value"> </div>
          </div>

          <div class="d-flex my-4">
            <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
            <div class="">
              <div class=" mb-0 product-details additional-info">Time Slot</div>
              <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
            </div>
          </div>

          <div class="border my-4"></div>
          <div class="my-4">
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3 default">
                <li>Chicken Biriyani</li>
              </ul>
              <div>* 1</div>
              <div><span>$ </span>150</div>
            </div>
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3  default">
                <li>Mutton Biriyani</li>
              </ul>
              <div>* 2</div>
              <div><span>$ </span>400</div>
            </div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between px-4 my-4">
            <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
            <div class="default product-details"><span>$ </span>550</div>
          </div>
          <div class="border-style"></div>
          <div class="text-center mb-4 my-4 reject-comment">Order rejected</div>
          <!-- body end -->
        </div>
        <!-- Accept Order - Ready to Delivery Order-->
        <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'accept-order'">
          <div class=" d-flex my-2">
            <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
            <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
              <div class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
            </div>
          </div>
          <hr class="hr-color">
          <div class=" d-flex">
            <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
              <div class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
            </div>
          </div>
          <div class="border my-4"></div>

          <div class="d-flex">
            <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
            <div>
              <div class=" mb-0 product-details additional-info">Delivery by</div>
              <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
            </div>
          </div>

          <div class="d-flex my-4">
            <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
            <div>
              <div class=" mb-0 product-details additional-info">Time Slot</div>
              <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
            </div>
          </div>

          <div class="border my-4"></div>
          <div class="my-4">
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3 default">
                <li>Chicken Biriyani</li>
              </ul>
              <div>* 1</div>
              <div><span>$ </span>150</div>
            </div>
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3  default">
                <li>Mutton Biriyani</li>
              </ul>
              <div>* 2</div>
              <div><span>$ </span>400</div>
            </div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between px-4 my-4">
            <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
            <div class="default product-details"><span>$ </span>550</div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between mb-4 my-4">
            <button type="submit" class="btn d-block m-auto delivery-order default w-100"
              (click)="deliveryOrder()">Order ready to delivery</button>
          </div>
          <!-- body end -->
        </div>
        <!--Complete Order -->
        <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'complete-order'">
          <div class=" d-flex my-2">
            <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
            <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
              <div class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
            </div>
          </div>
          <hr class="hr-color">
          <div class=" d-flex">
            <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
              <div class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
            </div>
          </div>
          <div class="border my-4"></div>

          <div class="d-flex">
            <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
            <div>
              <div class=" mb-0 product-details additional-info">Delivery by</div>
              <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
            </div>
          </div>

          <div class="d-flex my-4">
            <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
            <div class="">
              <div class=" mb-0 product-details additional-info">Time Slot</div>
              <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
            </div>
          </div>

          <div class="border my-4"></div>
          <div class="my-4">
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3 default">
                <li>Chicken Biriyani</li>
              </ul>
              <div>* 1</div>
              <div><span>$ </span>150</div>
            </div>
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3  default">
                <li>Mutton Biriyani</li>
              </ul>
              <div>* 2</div>
              <div><span>$ </span>400</div>
            </div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between px-4 my-4">
            <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
            <div class="default product-details"><span>$ </span>550</div>
          </div>
          <div class="border-style"></div>
          <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
          <!-- body end -->
        </div>
        <!--Cancelled Order -->
        <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'cancel-order'">
          <div class=" d-flex my-2">
            <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
            <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
              <div class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
            </div>
          </div>
          <hr class="hr-color">
          <div class=" d-flex">
            <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
            <div>
              <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
              <div class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
            </div>
          </div>
          <div class="border my-4"></div>

          <div class="d-flex">
            <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
            <div class="value"> </div>
          </div>

          <div class="d-flex my-4">
            <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
            <div class="">
              <div class=" mb-0 product-details additional-info">Time Slot</div>
              <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
            </div>
          </div>

          <div class="border my-4"></div>
          <div class="my-4">
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3 default">
                <li>Chicken Biriyani</li>
              </ul>
              <div>* 1</div>
              <div><span>$ </span>150</div>
            </div>
            <div class="d-flex justify-content-between px-4">
              <ul class="p-0 mb-3  default">
                <li>Mutton Biriyani</li>
              </ul>
              <div>* 2</div>
              <div><span>$ </span>400</div>
            </div>
          </div>
          <div class="border-style"></div>
          <div class="d-flex justify-content-between px-4 my-4">
            <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
            <div class="default product-details"><span>$ </span>550</div>
          </div>
          <div class="border-style"></div>
          <div class="text-center mb-4 my-4 cancel-comment">Order cancelled</div>
          <!-- body end -->
        </div>
      </div>



      <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="progress-orderId" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title title-color mb-0 me-2" id="offcanvasRightLabel">Offcanvas right</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          PROGRESS ORDERs
        </div>
      </div> -->





    </div>
    <!-- </div> -->

    <div *ngIf="selectedMenuItem === 'FoodItems'">
      <app-food-items></app-food-items>
    </div>

    <div *ngIf="selectedMenuItem === 'Financial'">
      <hr class="mx-0">
      <app-financial></app-financial>
    </div>

    <div *ngIf="selectedMenuItem === 'History'">
      <hr class="mx-0">
      <app-history></app-history>
    </div>
    <!-- <div *ngIf="selectedMenuItem === 'LogOut'">
  <hr class="mx-0">
  <div class="signout-overlay" >
    <div class="signout-content">
      <div class="sign">
        <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
        <div class="popup-message">Do you want to logout your account</div>
      </div>
      <hr class="mx-auto">
      <div class="popup-actions">
        <button class="btn signout-btn" (click)="toggleSignoutPopup()">No</button>
        <div class="vr px-0 "></div>
        <button class="btn signout-btn" routerLink="/login">Yes</button>
      </div>
    </div>
  </div>
</div> -->

  </div>




  <!-- <div class="grid-item1 d-lg-none d-block">
  <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)" [mobileData]="mobileData"></app-sidenav-items>

  

</div> -->
</div>