import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../logout/logout.component';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.css'],
})
export class OrdersPageComponent implements OnInit {
  mobileData:any ="orderpage-mobile";
  Display: string = 'new-order';
  date: any;
  time: any;
  timeInterval: any; 
  selectedMenuItem: string = 'Orders';
  currentMenuBar: 'liveOrders' | 'completed' | 'cancel' ='liveOrders';
  orderStatus: string = 'new-order'; // Default state is 'new-order'
  @Output() menuItemClicked = new EventEmitter<string>();
  selectedOrder: any;
  selectedOrderId: string | null = null;
  now: string | undefined;
  orderListData = [
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
  ];
  constructor(private renderer: Renderer2,public dialog: MatDialog,) {
   
    // console.log( this.time ," this.timeInterval");
    
  }



  ngOnInit() {
    this.updateDateAndTime();
    this.timeInterval =  setInterval(() => {
      const now = new Date();
      let hours: number = now.getHours();
      const minutes: string = String(now.getMinutes()).padStart(2, '0');
      const ampm: string = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; 
      const hoursString: string = String(hours).padStart(2, '0');
      this.time = `${hoursString}:${minutes} ${ampm}`;
    }, 1000);  
    window.scroll(0,0)
  }

  ngOnDestroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval); 
    }
  }

  updateDateAndTime() {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    this.date = `${day}/${month}/${year}`;
  }

  rejectOrder(): void {
    this.Display = 'reject-order';
  }
  acceptOrder(): void {
    this.Display = 'accept-order';
    // this.orderStatus = 'in-progress';
    // const order = this.orderListData.find(o => o.Orderid === orderId);
    // if (order) {
    //   order.status = 'in-progress';
    // }
  }
  deliveryOrder(): void {
    this.Display = 'complete-order';
    // this.orderStatus = 'ready-to-delivery'; 
    // const order = this.orderListData.find(o => o.Orderid === orderId);
    // if (order) {
    //   order.status = 'ready-to-deliver';
    // }
  }
  onClose() {
    // this.Display = 'new-order';
    this.selectedOrderId = null;
  } 


  // Method to handle selecting an order
  selectOrder(order: any) {
    this.selectedOrder = order; 
    console.log(order,"order");
    let additionInfo:any =JSON.parse(order.additionitem);
    // additionInfo.forEach((info:any)=>{
    //   this.selectedOrder.additionitem= info.items1;
    //   this.selectedOrder.additionitemTotal = info.totalcount;
    // });
    console.log(additionInfo);
    
    // this.selectedOrderId = orderId;
  }
  getSelectedOrderStatus() {
    // const order = this.orderListData.find(o => o.Orderid === this.selectedOrderId);
    // return order ? order.status : 'new-order';
  }
  convertTimestampToDate(timestamp: number) {
    if (!timestamp) {
      return 'Invalid date';
    }
    const date = new Date(timestamp * 1000);
    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    };

    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  onMenuItemSelected(menuItem: string): void {
    this.selectedMenuItem = menuItem;
  }

  onMenuClick(menuItem: 'liveOrders' | 'completed' | 'cancel'): void {
    this.menuItemClicked.emit(menuItem);
    this.currentMenuBar = menuItem;
  }


}
