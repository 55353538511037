import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
declare var bootstrap: any; 
@Component({
  selector: 'app-food-items',
  templateUrl: './food-items.component.html',
  styleUrls: ['./food-items.component.css']
})
export class FoodItemsComponent implements OnInit {
  selectedStockItem:string =''
  @Input() stockClicked = new EventEmitter<string>();
  currentstockBar: 'inStock' | 'outofStock' = 'inStock';
  selectedItem: any = null;
  constructor(private renderer: Renderer2 ,private elementRef: ElementRef, ) { }
  stock = [
    {
      src: "assets/images/stock.svg",
      name:"Biriyani",
      id:1,
      price:100,
    },
    {
      src: "assets/images/stock.svg",
      name:"Cake",
      id:2,
      price:500,
    },
    {
      src: "assets/images/stock.svg",
      name:"Shawarma",
      id:3,
      price:100,
    },
    {
      src: "assets/images/stock.svg",
      name:"Mutton Biriyani",
      id:4,
      price:300,
    },
    {
      src: "assets/images/stock.svg",
      name:"Wheat Chapati ",
      id:5,
      price:50,
    },
    {
      src: "assets/images/stock.svg",
      name:"Parotta",
      id:6,
      price:10,
    },
    {
      src: "assets/images/stock.svg",
      name:"Masala Dosa",
      id:7,
      price:15,
    },
    {
      src: "assets/images/stock.svg",
      name:"Ice Cream",
      id:8,
      price:25,
    },
    {
      src: "assets/images/stock.svg",
      name:"Cold Coffee",
      id:9,
      price:80,
    },
    {
      src: "assets/images/stock.svg",
      name:"Veg Biriyani",
      id:10,
      price:150,
    }
  ]
 
  outofStock: { src: string; name: string; isOutOfStock: boolean }[] = []; 
  ngOnInit(): void {
    window.scroll(0,0)
    // const element = this.elementRef.nativeElement.querySelector('.food-items');
    // if (element) {
    //   element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
    // }
  }

  onStockSelected(menuItem:  'inStock' | 'outofStock'): void {
    this.stockClicked.emit(menuItem);
    this.currentstockBar  = menuItem;
  }

  onClose() {
    this.selectedItem = null;
  }
  onItemClick(data: any): void {
    this.selectedItem = data; // Set the selected item data
    const backdropElement = document.querySelector('.show');
    if (backdropElement) {
      this.renderer.addClass(backdropElement, 'offcanvas-backdrop');
      this.renderer.addClass(backdropElement, 'fade');
      this.renderer.addClass(backdropElement, 'show');
    }
  }
  onOutofStock(){
    if (this.selectedItem) {
      // Remove the selected item from the 'stock' array
      this.stock = this.stock.filter(item => item !== this.selectedItem);

      // Add the selected item to the 'outofStock' array
      this.selectedItem.isOutOfStock = true;
      this.outofStock.push(this.selectedItem); // Push the selected item
      this.currentstockBar ='outofStock'
      // Clear the selected item
      this.selectedItem = null;
      const offcanvasElement = document.querySelector('.offcanvas.offcanvas-end.show');
      if (offcanvasElement) {
        this.renderer.removeClass(offcanvasElement, 'show');
      }
      const backdropElement = document.querySelector('.offcanvas-backdrop.fade.show');
      if (backdropElement) {
        this.renderer.removeClass(backdropElement, 'offcanvas-backdrop');
        this.renderer.removeClass(backdropElement, 'fade');
        this.renderer.removeClass(backdropElement, 'show');
      }
    }
  }

  onStock(): void {
    if (this.selectedItem) {
      
      // Find the index of the selected item in the stock array
      const index = this.stock.findIndex(item => item.name === this.selectedItem.name);

      if (index >= -1) {
        // Remove the item from the stock array
        const removedItem:any = this.outofStock.splice(index, 1)[0];

        // Add the item to the outofStock array
        this.stock.unshift(this.selectedItem);;

        // Add the 'outof-stock' class to the selected item
        // this.selectedItem.class = 'outof-stock';
        this.currentstockBar ='inStock'
        // Clear the selected item
        this.selectedItem = null;
        const offcanvasElement = document.querySelector('.offcanvas.offcanvas-end.show');
        if (offcanvasElement) {
          this.renderer.removeClass(offcanvasElement, 'show');
        }
        const backdropElement = document.querySelector('.offcanvas-backdrop.fade.show');
        if (backdropElement) {
          this.renderer.removeClass(backdropElement, 'offcanvas-backdrop');
          this.renderer.removeClass(backdropElement, 'fade');
          this.renderer.removeClass(backdropElement, 'show');
        }
      }
    }
  }
}
