<div class="signout-overlay" >
  <div class="signout-content">
    <div class="sign">
      <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
      <div class="popup-message">Do you want to logout your account</div>
    </div>
    <hr class="mx-auto">
    <div class="popup-actions">
      <button class="btn signout-btn" (click)="toggleSignoutPopup()">No</button>
      <div class="vr px-0 "></div>
      <button class="btn signout-btn" routerLink="/login">Yes</button>
    </div>
  </div>
</div>
