import { Component, OnInit } from '@angular/core';
declare var $: any;


declare global {
  interface JQuery {
      datepicker(options?: any): JQuery;
      datepicker(method: string): JQuery;
      datepicker(method: string, option: any): JQuery;
  }
}
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  showShadow: boolean = false;
  showSize: boolean = true;
  showSort: boolean = false;
  orderListData = [
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }
  toggleDateField() {

    this.showSort = !this.showSort
    this.showShadow = this.showSort;
    this.showSize = !this.showSort;
    // }
  }
  // ngAfterViewInit(): void {
  //   // Initialize the datepicker
  //   $('#datepicker').datepicker({
  //     dateFormat: 'dd/mm/yy',
  //     showButtonPanel: true,
  //     showOtherMonths: true,
  //     selectOtherMonths: true,
  //     changeMonth: true,
  //     changeYear: true,
  //     beforeShowDay: function(date) {
  //       // Disable weekends
  //       var day = date.getDay();
  //       return [(day !== 0 && day !== 6), ''];
  //     },
  //     beforeShow: function(input, inst) {
  //       setTimeout(function() {
  //         // Custom prev and next buttons
  //         inst.dpDiv.find('.ui-datepicker-prev').html('<i class="your-prev-icon-class"></i>');
  //         inst.dpDiv.find('.ui-datepicker-next').html('<i class="your-next-icon-class"></i>');
  //       }, 1);
  //     }
  //   });

  //   // Initialize the Date Range Picker
  //   $('#daterange').daterangepicker({
  //     opens: 'center',
  //     autoUpdateInput: false,
  //     locale: {
  //       format: 'DD/MM/YYYY',
  //       separator: ' to ',
  //       applyLabel: 'Apply',
  //       cancelLabel: 'Cancel'
  //     }
  //   }, function(start, end) {
  //     $('#daterange').val(start.format('DD/MM/YYYY') + ' to ' + end.format('DD/MM/YYYY'));
  //   });
  // }
  ngAfterViewInit(): void {


//   $(document).ready(function() {
//     var dateFormat = 'dd/mm/yy';
//     var fromDateInput = $('#fromDate');
//     var toDateInput = $('#toDate');
//     var additionalInput = $('#datepicker-input');
//     var errorMessage = $('#error-message');
//     function updateDatepicker() {
//     $('#datepicker').datepicker({
//         dateFormat: dateFormat,
//         beforeShowDay: function(date: Date) {
//             // Disabling future dates
//             const today = new Date();
//             return [date <= today, ''];
//         },
   
//     });
//   }
//   function checkDateRange() {
//     var fromDate = fromDateInput.datepicker('getDate');
//     var toDate = toDateInput.datepicker('getDate');

//     if (fromDate && toDate) {
//       if (fromDate > toDate) {
//         errorMessage.text('From date must be less than or equal to To date.');
//         toDateInput.val('');
//       } else {
//         errorMessage.text('');
//         $('#datepicker').datepicker('option', {
//           minDate: fromDate,
//           maxDate: toDate
//         });
//       }
//     } else if (fromDate) {
//       $('#datepicker').datepicker('option', 'minDate', fromDate);
//       $('#datepicker').datepicker('option', 'maxDate', null);
//     } else if (toDate) {
//       $('#datepicker').datepicker('option', 'minDate', null);
//       $('#datepicker').datepicker('option', 'maxDate', toDate);
//     }
//   }

//   fromDateInput.datepicker({
//     dateFormat: dateFormat,
//     onSelect: function() {
//       checkDateRange();
//     }
//   });

//   toDateInput.datepicker({
//     dateFormat: dateFormat,
//     onSelect: function() {
//       checkDateRange();
//     }
//   });

//   // Initialize datepicker
//   updateDatepicker();
  
// });

$(document).ready(function() {
  var dateFormat = 'dd/mm/yy';
  var fromDateInput = $('#fromDate');
  var toDateInput = $('#toDate');
  var additionalInput = $('#datepicker-input');
  var errorMessage = $('#error-message');

  function updateDatepicker() {
    $('#datepicker').datepicker({
      dateFormat: dateFormat,
      beforeShowDay: function(date:Date) {
        var dateStr = $.datepicker.formatDate(dateFormat, date);
        var fromDateStr = $.datepicker.formatDate(dateFormat, fromDateInput.datepicker('getDate'));
        var toDateStr = $.datepicker.formatDate(dateFormat, toDateInput.datepicker('getDate'));

        if (fromDateStr && toDateStr) {
          var fromDate = new Date(fromDateStr);
          var toDate = new Date(toDateStr);
          if (date >= fromDate && date <= toDate) {
            return [true, 'highlight-date'];
          }
        }
        return [true, ''];
      }
    });
  }

  function checkDateRange() {
    var fromDate = fromDateInput.datepicker('getDate');
    var toDate = toDateInput.datepicker('getDate');

    if (fromDate && toDate) {
      if (fromDate > toDate) {
        errorMessage.text('From date must be less than or equal to To date.');
        toDateInput.val('');
      } else {
        errorMessage.text('');
        $('#datepicker').datepicker('option', {
          minDate: fromDate,
          maxDate: toDate
        });
      }
    } else if (fromDate) {
      $('#datepicker').datepicker('option', 'minDate', fromDate);
      $('#datepicker').datepicker('option', 'maxDate', null);
    } else if (toDate) {
      $('#datepicker').datepicker('option', 'minDate', null);
      $('#datepicker').datepicker('option', 'maxDate', toDate);
    }

    // Redraw datepicker to apply highlight
    $('#datepicker').datepicker('refresh');
  }

  fromDateInput.datepicker({
    dateFormat: dateFormat,
    onSelect: function() {
      checkDateRange();
    }
  });

  toDateInput.datepicker({
    dateFormat: dateFormat,
    onSelect: function() {
      checkDateRange();
    }
  });

  // Initialize datepicker
  updateDatepicker();
});


}
onClose() {
  // this.Display = 'new-order';
}
}
