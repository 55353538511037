<div class=" overflow-auto row"  >
  <div class="d-flex">
  <div class=" col-8 px-0">
  <table class="history-table mt-2">
    <thead class="py-3">
      <tr class="history-table-head">
        <th class="text-center history-order-column py-3 px-1 history-head">Order ID</th>
        <th class="text-center history-order-column py-3 px-1 history-head">Product Details</th>
        <th class="text-center history-order-column py-3 px-1 history-head">Amount</th>
        <th class="text-center history-order-column py-3 px-1 history-head">Action</th>
        <th class="text-center history-arrow-column py-2 px-1 "> 
          <div class="btn-group" >
          <button type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Sort by
          </button>
          <ul class="dropdown-menu shadow pointer">
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="volvo"> 
                <div class="fw-bold ms-2">Completed</div>
              </div>
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="saab"> 
                <div class="fw-bold ms-2">Cancel</div>
              </div>
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="audi"> 
                <div class="fw-bold ms-2">Reject</div>
              </div>
          </ul>
        </div>
        </th>
    </tr>
 </thead>
    <tbody class="history-body">
      <tr class="py-2 order-row history-head-solid m-0 mb-3 " *ngFor="let order of orderListData">
        <td class="text-center mt-2 product-details default history-order-columns py-0 px-1 product-name mt-3 ">{{order.Orderid}}</td>
        <td class="history-order-columns py-0 px-1 product-name">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center">
            <li>{{order.productDetails}}</li>
          </ul>
            <div  class="additional-order title-color text-center">+ 2 more items</div>
        </td>
        <td class="history-order-columns text-center history-product-details default py-0 px-1 product-name mt-3 ">${{order.amount}}</td>
        <td class="text-center px-1 py-0  history-order-columns mt-2">
          <div class="d-flex justify-content-evenly">
            <button class="btn completed px-3 py-2 d-flex" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Completed <img
              src="/assets/images/complete-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1" ></button>
          </div>
        </td>
        <!-- <td class="text-center px-1 py-0  history-order-column">
          <div class="d-flex justify-content-evenly">
            <button class="btn cancel px-md-3 px-2 py-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#cancelId" aria-controls="offcanvasRight">Cancel</button>
          </div>
        </td> -->
        <!-- <td class="history-order-column text-end"> -->
          <!-- <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon   ps-md-2 m-0 ps-1"> -->
        <!-- </td> -->
      </tr>
    </tbody>
  </table>
</div>
<!-- <div class="btn-group   py-2 px-0 col-2 " >
  <button type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Sort by
  </button>
  <ul class="dropdown-menu shadow pointer">
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="volvo"> 
        <div class="fw-bold ms-2">Completed</div>
      </div>
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="saab"> 
        <div class="fw-bold ms-2">Cancel</div>
      </div>
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="audi"> 
        <div class="fw-bold ms-2">Reject</div>
      </div>
  </ul>
</div> -->

  <div id="datepicker" class="col-4 text-center position-relative px-0" >
      <input type="text" id="fromDate" placeholder="From Date" class="date-from">
      <input type="text" id="toDate" placeholder="To Date" class="date-to">
      <div id="error-message" style="color: red;"></div>

  </div>
</div>

<!-- <div class="btn-group text-center history-arrow-column py-2 px-1 " >
  <button type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Sort by
  </button>
  <ul class="dropdown-menu shadow pointer">
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="volvo"> 
        <div class="fw-bold ms-2">Completed</div>
      </div>
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="saab"> 
        <div class="fw-bold ms-2">Cancel</div>
      </div>
      <div class="dropdown-item d-flex pointer">
        <input type="radio" name="sort" value="audi"> 
        <div class="fw-bold ms-2">Reject</div>
      </div>
  </ul>
</div> -->
<!-- orer-delivery -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <div class="d-flex">
    <div class="default title-color mt-1 me-4" id="offcanvasRightLabel">Order Id</div>
    <div class="text-center  product-details  name"> 1000000012</div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
    <!--Complete Order -->
    <div class="offcanvas-body py-4  overflow-auto" >
      <div class=" d-flex my-2">
      <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
<img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
   <div>
    <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
    <div  class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
  </div>
   </div>
   <hr class="hr-color">
   <div class=" d-flex">
    <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
 <div>
  <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
  <div  class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
</div>
 </div>
 <div class="border my-4"></div>

 <div class="d-flex">
  <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery" >
  <div>
  <div class=" mb-0 product-details additional-info">Delivery by</div>
  <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
</div>
</div>

<div class="d-flex my-4">
  <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time" >
  <div class="">
  <div class=" mb-0 product-details additional-info">Time Slot</div>
  <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
</div>
</div>

<div class="border my-4"></div>
<div class="my-4">
<div class="d-flex justify-content-between px-4">
<ul class="p-0 mb-3 default">
  <li>Chicken Biriyani</li>
</ul>
<div>* 1</div>
<div><span>$ </span>150</div>
</div>
<div class="d-flex justify-content-between px-4">
  <ul class="p-0 mb-3  default">
    <li>Mutton Biriyani</li>
  </ul>
  <div>* 2</div>
  <div><span>$ </span>400</div>
  </div>
</div>
  <div class="border-style"></div>
<div class="d-flex justify-content-between px-4 my-4">
  <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
  <div class="default product-details"><span>$ </span>550</div>
</div>
  <div class="border-style"></div>
  <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
<!-- body end -->
    </div>
     <!--Cancelled Order -->
     <!-- <div class="offcanvas-body py-4  overflow-auto" >
      <div class=" d-flex my-2">
<img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
   <div>
    <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
    <div  class="title-color default mt-1">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
  </div>
   </div>
   <hr class="hr-color">
   <div class=" d-flex">
    <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
 <div>
  <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
  <div  class="title-color default mt-1">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
</div>
 </div>
 <div class="border my-4"></div>

 <div class="d-flex">
  <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery" >
  <div class="value"> </div>
</div>

<div class="d-flex my-4">
  <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time" >
  <div class="">
  <div class=" mb-0 product-details additional-info">Time Slot</div>
  <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
</div>
</div>

<div class="border my-4"></div>
<div class="my-4">
<div class="d-flex justify-content-between px-4">
<ul class="p-0 mb-3 default">
  <li>Chicken Biriyani</li>
</ul>
<div>* 1</div>
<div><span>$ </span>150</div>
</div>
<div class="d-flex justify-content-between px-4">
  <ul class="p-0 mb-3  default">
    <li>Mutton Biriyani</li>
  </ul>
  <div>* 2</div>
  <div><span>$ </span>400</div>
  </div>
</div>
  <div class="border-style"></div>
<div class="d-flex justify-content-between px-4 my-4">
  <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
  <div class="default product-details"><span>$ </span>550</div>
</div>
  <div class="border-style"></div>
  <div class="text-center mb-4 my-4 cancel-comment">Order cancelled</div>

    </div> -->
  </div>

<!-- <div class="mx-auto  p-1" [ngClass]="{'shadow': showShadow}">
  <div class=" text-center px-0 size">
    <div class=" " (click)="toggleDateField()" *ngIf="!showSort">
      <label for="" class="labelname fw-bold m-2 pe-2 column ">Sort by</label>
    </div>
    <div class=" " *ngIf="showSort" >
      <label for="" class="labelname fw-bold m-2  pe-2 column ">Sort by</label>
    </div>
      <img  src="assets/images/up-arrow.svg" id="upArrow" class="ms-4 pointer up-arrow position-absolute"
      alt="arrow" *ngIf="showSort" (click)="toggleDateField()">
  
    <img src="assets/images/down-arrow.svg" id="downArrow" class="ms-4 pointer down-arrow position-absolute"
    alt="downArrow" *ngIf="!showSort" (click)="toggleDateField()">

  </div>
  <div [class.hidden]="!showSort" class="mx-auto pt-0 ">
    <hr class="mx-auto my-2">
   
      <div class="mt-2">
        <div class="d-flex ms-2 " for="">
          <input type="radio"class="me-2">
          <div class="fw-bold ms-2">Completed</div>
          </div>
        <div class="d-flex ms-2 " for="">
          <input type="radio"class="me-2">
          <div class="fw-bold ms-2">Cancel</div>
        </div>
        <div class="d-flex ms-2" for="">
          <input type="radio"class="me-2">
          <div class="fw-bold ms-2">Reject</div>
        </div>
      </div>
    
  </div>
</div> -->