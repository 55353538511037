

    <div class="d-lg-block d-none mt-4 food-items">
      <div class="row ">
        <div class="text-start col-6">
        <div class="order-title me-3 px-3 pt-2"(click)="onStockSelected('inStock')" [class.selectedStock] = "currentstockBar === 'inStock'"   [ngClass]="{'stock-title': currentstockBar === 'inStock'}">In Stock</div>
        <div class="order-title me-3 px-3 pt-2"(click)="onStockSelected('outofStock')" [class.selectedStock] = "currentstockBar === 'outofStock'"  [ngClass]="{'stock-title': currentstockBar === 'outofStock'}">Out of Stock</div>
      </div>
        </div>
      </div>
      <div class="d-lg-none d-block mt-4">
          <div class="text-start d-flex justify-content-between ">
          <div class="order-title me-3 px-3 pt-2"(click)="onStockSelected('inStock')" [class.selectedStock] = "currentstockBar === 'inStock'"   [ngClass]="{'stock-title': currentstockBar === 'inStock'}">In Stock</div>
          <div class="order-title me-3 px-3 pt-2"(click)="onStockSelected('outofStock')" [class.selectedStock] = "currentstockBar === 'outofStock'"  [ngClass]="{'stock-title': currentstockBar === 'outofStock'}">Out of Stock</div>
        </div>
      </div>
  <!-- order-details table -->
<div class="border"></div>


<div class="d-flex justify-content-between  pt-4">
  <div class="d-flex items mt-2"> <small>Total food items :</small> <small class="ms-2">{{ currentstockBar === 'inStock' ? stock.length : outofStock.length }}</small></div>
<div class=" col-lg-4 me-2 seach-place">
  <div class="search-container">
    <input type="text" placeholder="Search  Foods items" class="text-start">
    <button type="submit" ><i class="fa fa-search"></i></button>
  </div>
</div>
</div>

<div class="mt-4 row  show-stock" *ngIf="currentstockBar === 'inStock'">
  <div class="col-6 col-md-3  text-center py-3 " *ngFor="let data of stock">
  <img  [src]="data.src" class="w-30 " alt="stock" type="button" data-bs-toggle="offcanvas" data-bs-target="#new-stockId" aria-controls="offcanvasRight" (click)="onItemClick(data)">  
  <div class="my-2 stock-name"> {{data.name}}</div>
</div>             
</div>
<div *ngIf="outofStock.length > 0">
<div class="mt-4 row  show-stock" *ngIf="currentstockBar === 'outofStock'">
  <div class="col-6 col-md-3 text-center py-3 " *ngFor="let data of outofStock">
  <img  [src]="data.src" class="w-30 outof-stock" alt="stock"type="button" data-bs-toggle="offcanvas" data-bs-target="#new-stockId" aria-controls="offcanvasRight" (click)="onItemClick(data)">  
  <div class="my-2 stock-name"> {{data.name}}</div>
</div>  
</div>
</div>

<!-- canvas -->
<div class="offcanvas offcanvas-end p-3" tabindex="-1" id="new-stockId" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <div class="d-flex">
    <div class="text-center  product-details  stockname" id="offcanvasRightLabel">{{ selectedItem?.name }}</div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
  <div class="offcanvas-body py-0">
  <div *ngIf="selectedItem?.src">
    <img  [src]="selectedItem?.src" class="w-100 p-4 mt-3 " alt="stock">  
  </div>
  </div>
  <div class="text-center stock-color"> {{ selectedItem?.name }}</div>
  <div class="text-center stock-price">$ {{ selectedItem?.price }}</div>

  <div class="mt-4" *ngIf="currentstockBar === 'inStock'">
    <button type="submit" class="btn d-block m-auto out-of-order default w-100 py-2"(click)="onOutofStock()">Marked as Out of stock</button>
  </div>

  
  <div class="mt-4" *ngIf="currentstockBar === 'outofStock'">
    <button type="submit" class="btn d-block m-auto out-of-order default w-100 py-2"(click)="onStock()">Marked as available</button>
  </div>
</div>